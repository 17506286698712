// Compiler result to JSON file
import { createComponent } from 'js/declViewModelService';
import { registerDynImportEntry } from 'js/moduleLoader';
import AwParseService from 'js/awParseService';
import AwFilterService from 'js/awFilterService';
import { getField } from 'js/utils';
import { Fragment } from 'react';
let filterSer = AwFilterService.instance;

/*
render function
*/
import { awPropertyRichTextAreaValRenderFunction } from 'js/AwPropertyRichTextAreaValService';

/*
ctx imports
*/

const ctxImportObj = {};

/*
dynamic import
 */
registerDynImportEntry('js/AwPropertyRichTextAreaValService', () => import('js/AwPropertyRichTextAreaValService'));
;

/*
view model
 */
const declViewModelJson = {
  "schemaVersion": "1.0.0",
  "imports": [],
  "actions": {
    "render": {
      "method": "awPropertyRichTextAreaValRenderFunction",
      "options": {
        "elementRefList": ["richTextAreaVal"]
      },
      "deps": "js/AwPropertyRichTextAreaValService"
    },
    "onUnmount": {
      "actionType": "JSFunction",
      "method": "unmountEditor",
      "inputData": {
        "editor": "{{data.ckEditorInstance}}"
      },
      "deps": "js/AwPropertyRichTextAreaValService"
    }
  },
  "data": {
    "ckEditorInstance": ""
  },
  "conditions": {},
  "onEvent": [],
  "lifecycleHooks": {
    "render": "render",
    "onUnmount": "onUnmount"
  },
  "props": {
    "prop": {
      "type": "field"
    }
  },
  "_viewModelId": "AwPropertyRichTextAreaVal",
  "_uniqueViewModelId": "AwPropertyRichTextAreaVal",
  "ctx": {}
};

/*
define component
*/
const Component = createComponent(declViewModelJson, awPropertyRichTextAreaValRenderFunction, {
  "elementRefList": ["richTextAreaVal"]
}, ctxImportObj);
export default Component;